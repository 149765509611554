export default function testCanvas(doc) {
    let canvas = document.getElementById(doc);
    let ctx = canvas.getContext("2d");

    function drawArc(centerX, centerY, R, r, color, endX, endY) {
        ctx.lineWidth = 1.5;

        ctx.beginPath();
        ctx.arc(centerX, centerY, R, 0, Math.PI * 2);
        ctx.strokeStyle = color;
        ctx.setLineDash([5, 7]);
        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
        ctx.arc(centerX, centerY, r, 0, Math.PI * 2);
        ctx.setLineDash([0, 0]);
        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(endX, endY);
        ctx.setLineDash([5, 4]);
        ctx.stroke();
        ctx.closePath();
    }

    drawArc(80, 135, 70, 4, "#4D7BFF", 10, 135);
    drawArc(170, 80, 55, 4, "#B3B3B3", 215, 50);
    drawArc(220, 150, 45, 4, "#F3B111", 240, 110);
    drawArc(300, 80, 50, 4, "#B3B3B3", 300, 32);
    drawArc(320, 180, 50, 4, "#B3B3B3", 300, 230);
}