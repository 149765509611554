<template>
  <div class="container">
        <div class="title">
            <h3>服务特点</h3>
            <p>Service Features</p>
        </div>
        <div class="tedian-box">
            <ul class="tedian-ul">
                <li>
                    <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe650;</i></div></div>
                    <div class="title1">多端联动</div>
                    <div class="title2">PC端，手机端数据联动</div>
                </li>
                <li>
                    <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe64e;</i></div></div>
                    <div class="title1">分级管控</div>
                    <div class="title2">分级上报，逐级管控</div>
                </li>
                <li>
                    <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe653;</i></div></div>
                    <div class="title1">多级预警</div>
                    <div class="title2">多级预警，防止安全事故</div>
                </li>
                <li>
                    <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe64f;</i></div></div>
                    <div class="title1">数据云存储</div>
                    <div class="title2">数据云端存储，实时保存数据</div>
                </li>
                <li>
                    <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe651;</i></div></div>
                    <div class="title1">巡检智能化</div>
                    <div class="title2">巡检留痕，过程可追溯</div>
                </li>
                <li>
                    <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe652;</i></div></div>
                    <div class="title1">产品定制化</div>
                    <div class="title2">定制化产品，满足客户需求</div>
                </li>
            </ul>
       </div>
       <div class="title">
          <h3>三大版本</h3>
          <p>Three Platforms</p>
       </div>
       <Swiper-2></Swiper-2>
       <div class="title" style="margin-top:20px">
          <h3>产品优势</h3>
          <p>Product Advantages</p>
       </div>
       <div class="youshi">
           <div class="img-box">
               <!-- style="width:100%;height:100%" -->
               <img v-lazy="require('../assets/images/chanping.webp')" >
               <!-- <img v-lazy="require('../assets/images/1.webp')" style="width:190px;height:120px;margin-bottom:-17px">
               <img v-lazy="require('../assets/images/6.webp')" style="width:150px;height:120px;margin-bottom:-17px">
               <img v-lazy="require('../assets/images/3.webp')" style="width:160px;height:90px;margin-left:-35px;margin-top:15px">
               <img v-lazy="require('../assets/images/2.webp')" style="width:125px;height:66px;margin-left:-70px">
               <img v-lazy="require('../assets/images/4.webp')" style="width:140px;height:94px;margin-left:-60px">
               <img v-lazy="require('../assets/images/5.webp')" style="width:150px;height:110px;margin-left:-60px">
               <img v-lazy="require('../assets/images/7.webp')" style="width:95px;height:65px;margin-left:-55px"> -->
           </div>
           <div class="text-box">
               <div class="text-title">高精自研设备</div>
               <div class="text">设备自主研发，售后服务全程更进</div>
           </div>
       </div>
       <div class="youshi">
           <div class="text-box">
               <div class="text-title">塔群作业防碰撞</div>
               <div class="text">塔吊作业实时预警，禁行区告警</div>
           </div>
           <div class="img-box" style="padding-left:11%">
                <canvas id="myCanvas" width="400" height="270" style="display: block">您的浏览器不支持canvas！</canvas>
           </div>
       </div>
       <div class="youshi">
           <div class="img-box" style="border:none">
               <img v-lazy="require('../assets/images/diannao.webp')" style="width:422px;height:279px">
               <img v-lazy="require('../assets/images/shouji.webp')" style="width:105px;height:211px">
           </div>
           <div class="text-box" style="border:none">
               <div class="text-title">远程锁机</div>
               <div class="text">远程指令限制塔机使用</div>
           </div>
       </div>
  </div>
</template>

<script>
import Swiper2 from '../components/Swiper2.vue';
import towerGroup from '../assets/canvas/towerGroup.js';
export default {
    components:{ Swiper2 },
    mounted(){
        this.$nextTick(()=>{
            towerGroup("myCanvas")
        })
    },
    created(){
        document.body.style.height = "100%"
        document.documentElement.style.height = "100%"
    }
}
</script>

<style lang="scss" scoped>
.container{
    width: 1200px;
    height: auto;
    margin: 0 auto;
}
.title{
    color: #4D4D4D;
    letter-spacing: 3px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3{
        font-size: 21px;
    }
    p{
        color: #808080;
        font-size: 15px;
        letter-spacing: 0;
    }
}
.tedian-box{
    width: 100%;
    height: 350px;
    background-size: 100% 100%;
}
.tedian-ul{
    width: 844px;
    height: 310px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
        width: 230px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon-box{
            width: 84px;
            height: 84px;
            background: #E8EBF2;
            box-shadow: 7px 5px 16px 0px #C5C5C5, -8px -8px 27px 5px #FFFFFF, 0px 3px 8px 0px #F4F7FF, 2px 2px 8px 0px #DBDEE4;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-box1{
                background: #4D97FF;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 1px;
                 i{
                    font-size: 30px;
                    color: #fff;
                }
            }
        }
        .title1{
            color: #4D4D4D;
            font-weight: 600;
            margin: 5px 0;
            font-size: 15px;
        }
        .title2{
            color: #808080;
            font-size: 13px;
        }
        img{
            box-shadow: 7px 5px 16px 0px #C5C5C5, -8px -8px 27px 5px #FFFFFF, 0px 3px 8px 0px #F4F7FF, 2px 2px 8px 0px #DBDEE4;
        }
    }
}
.youshi{
    padding: 0 150px;
    display: flex;
    margin: 0px 0;
    .img-box{
        width: 583px;
        height: 384px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 2px dashed #ccc;
    }
    .text-box{
        border-bottom: 2px dashed #ccc;
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .text-title{
            color: #4D7BFF;
            font-weight: bold;
            font-size: 18px;
        }
        .text{
            color: #808080;
            font-size: 15px;
            margin-top: 10px;
            letter-spacing: 1px;
        }  
    }
}
</style>