<template>
  <div class="swiper-box2">
    <div class="swiper-container2">
      <div class="swiper-wrapper" @click="swiperClick">
        <div class="swiper-slide">
          <img src="../assets/images/行政版.webp" alt="">
          <div class="text-box">
              <div class="text-title">行政版本</div>
              <div class="text">针对地方政府部门行政管理要求，直接获取工地起重设备施工详情，实现辖区内所有工地的安全管控。</div>
          </div>
        </div>
        <div class="swiper-slide">
           <img src="../assets/images/建机版.webp" alt="">
          <div class="text-box">
              <div class="text-title">建机版本</div>
              <div class="text">覆盖生产，销售，收款，服务四大模块，对厂商机器可以实现远程锁机功能。</div>
          </div>
        </div>
        <div class="swiper-slide">
          <img src="../assets/images/租赁版.webp" alt="">
          <div class="text-box">
              <div class="text-title">租赁版本</div>
              <div class="text">满足租赁商对设备管理的需求，巡检智能化，维修过程留痕，实现对设备的精细化管理。</div>
          </div>
        </div>
      </div>
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-pagination"></div> -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
    methods:{
        swiperClick(){

        },
    },
    mounted(){
        this.$nextTick(()=>{
            var mySwiper = new Swiper(".swiper-container2", {
                speed: 1200, //滑动速度
                loop: true,
                spaceBetween: 0, //每个元素的间距
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false, //鼠标操作后不会停止
                },
                preventClicks : false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                effect: 'coverflow',
                centeredSlides: true,
                spaceBetween: '0%',
                slidesPerView: 2,
                coverflowEffect: {
                    rotate: 0,
                    stretch: 120,
                    depth: 130,
                    modifier: 1.1,
                    slideShadows: false,
                },

                observer: true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,//修改swiper的父元素时，自动初始化swiper
            });

            mySwiper.el.onmouseover = function () {
                mySwiper.autoplay.stop();
            };
            mySwiper.el.onmouseleave = function () {
                mySwiper.autoplay.start();
            };
        })
    }
}
</script>

<style lang="scss" scoped>
.swiper-box2{
    overflow: hidden;
    height: 300px;
    width: 1200px;
    .swiper-container2{
        width: 100%;
        height: 100%;
    }
    .swiper-slide{
        display: flex;
        align-items: center;
        padding: 0 40px;
        height: 100%;
        width: 100%;
        background: #dadee4;
        //background: #fff;
        //box-shadow: 7px 5px 16px 0px #C5C5C5, -8px -8px 27px 5px #FFFFFF, 0px 3px 8px 0px #F4F7FF, 2px 2px 8px 0px #DBDEE4;
        img{
            width: 300px;
            height: 170px;
        }
        .text-box{
            margin-left: 20px;
            .text-title{
                font-size: 17px;
                font-weight: bold;
                text-align: center;
            }
            .text{
                color: #666666;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 22px;
                margin-top: 15px;
            }
        }
    }
}
</style>